import { useState } from 'react'

function useAccordion() {
  const [ref, setRef] = useState(null)
  const [height, setHeight] = useState(0)

  const toggleAccordion = () => {
    if (height === 0) {
      const newHeight = getNewHeight()
      setHeight(newHeight)
    } else {
      setHeight(0)
    }
  }

  function getNewHeight() {
    return ref.scrollHeight
  }

  return [setRef, toggleAccordion, height, setHeight]
}

export default useAccordion
