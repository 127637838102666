import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import PricingPageTemplate from '../components/PricingPageTemplate'
import Layout from '../components/Layout'

const PricingPage = ({ data }) => {
  return (
    <Layout>
      <PricingPageTemplate data={data.allMarkdownRemark} />
    </Layout>
  )
}

PricingPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.shape({
        node: PropTypes.shape({
          frontmatter: PropTypes.object,
        }),
      }),
    }),
  }),
}

export default PricingPage

export const pricingPageQuery = graphql`
  query PricingPage {
    allMarkdownRemark(filter: {frontmatter: {templateKey: {eq: "pricing-page"}}}) {
      edges {
        node {
          frontmatter {
            plan_month
            is_initial_plan
            title
            meta {
              meta_title
              meta_description
            }
            banner3 {
              heading1
              checklist
              ft_button {
                text
                url
              }
            }
            offerings {
              blurbs {
                image
                imagewidth
                heading
                text
                orientation
                link_url
                link_label
                checklist
              }
            }
            months {
              sixmonths_label
              oneyear_label
              twoyears_label
            }
            pricing {
              discount
              discount_label
              heading
              headingitems
              plans {
                description
                items
                items2
                plan
                plan_id
                price
                most_popular
                image
                submit_label
                submit_url
              }
            }
            faq {
              faq_header
              faqs {
                question
                answer
              }
            }
          }
        }
      }
    }
  }
`
