import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

const Pricing = ({ data }) => {
  console.log(data[0].image)
  return (
    <div className='columns is-multiline'>
      {data.map(price => (
        <PriceContainer
          key={price.plan}
          mostPopular={price.most_popular}
          className='Prices column pricing-column'
        >
          <section className='section'>
            <div className='has-text-centered'>
              <img alt='plan picture' src={price.image} />
            </div>
            <h4 className='has-text-centered has-text-weight-semibold'>
              {price.plan}
            </h4>
            <h2 className='price'>
              <span className='integerPart'>€{parseInt(price.price)}</span>
              <span className='decimalPart'>{(price.price % 1).toLocaleString(undefined, { minimumFractionDigits: 2 }).substring(1, 10)}</span>
            </h2>
            <p className='price_description'>{price.description}</p>
            <div className='ApartmentsAndUsers'>
              {price.items.map(item => (
                <div key={item}>
                  {item}
                </div>
              ))}
            </div>

            <div className='Extras'>
              {price.items2.map(item => (
                <div key={item}>
                  {item}
                </div>
              ))}
            </div>
          </section>

          <div className='CustomPlanSection'>
            <ChoosePlan
              className='button free-trial-btn1'
              href={price.submit_url}
              target='_blank'
            >
              {price.submit_label}
            </ChoosePlan>
          </div>

        </PriceContainer>
      ))}
    </div>
  )
}
Pricing.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      plan: PropTypes.string,
      banner3: PropTypes.banner3,
      price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      description: PropTypes.string,
      items: PropTypes.array,
      items2: PropTypes.array,
      image: PropTypes.string,
    }),
  ),
}

export default Pricing

const PriceContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  border: 1px solid #eaecee;
  border-radius: 10px;
  margin-bottom: 1.5rem;

  .section {
    flex: 1;
  }

  .CustomPlanSection {
    padding: 1rem;

    @media(min-width: 768px) {
      padding: 0;
    }
  }

  ${({ mostPopular }) => mostPopular && css`
    border-color: #4d87ed;

    .section {
      margin-top: 35px;

      @media(min-width: 768px) {
        margin-top: 0;
      }
    }

    &::before {
      position: absolute;
      content: "Mais popular";
      top: 0;
      left: 0;
      background-color: #4d87ed;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      color: #fff;
      font-size: 12px;
      font-weight: 500;
      line-height: 25px;
      width: 100%;
      padding: 5px 0;
      text-align: center;
    }
  `}
`

const ChoosePlan = styled.a`
	width: 100%;
`
