import { useStaticQuery, graphql } from 'gatsby'

function getCategories(allCategories) {
  return allCategories.reduce((categories, { fieldValue }) => {
    categories.push(fieldValue)
    return categories
  }, [])
}

export default () => {
  const { allCategories } = useStaticQuery(
    graphql`
      query Categories {
        allCategories: allMarkdownRemark(filter: { frontmatter: { templateKey: { eq: "article-page" } } }) {
          group(field: frontmatter___category) {
            fieldValue
          }
        }
      }
    `
  )

  return getCategories(allCategories.group)
}
