import React  from "react";
import styled, { css } from "styled-components"
import { useAccordion } from '../../hooks'

export default function FAQ({ item }) {
  const [ref, toggleAccordion, height] = useAccordion()

  return (
    <>
      <Item key={item.key} className={height ? 'active' : ''}>
        <Question onClick={toggleAccordion}>
          {item.question}
          <svg width="14" height="8" viewBox="0 0 14 8">
            <polygon fill="#283445" points="632.525 33 634 34.394 626.988 41 620 34.393 621.477 33.001 626.991 38.214" transform="translate(-620 -33)" />
          </svg>
        </Question>
        <Content
          ref={ref}
          height={height}
          dangerouslySetInnerHTML={{ __html: item.answer }}
        />
      </Item>
    </>
  )
}


const Item = styled.div`
  border-top: 1px solid #eaecee;
  border-left: 0;
  border-right: 0;
  overflow: hidden;
  transition: height .35s ease-in-out;

  &:last-child {
    border-bottom: 1px solid #eaecee;
  }

  svg {
    min-width: 14px;
    transition: transform .35s ease-in-out;
  }

  &:hover, &.active {
    svg polygon {
      fill: #2d50f4;
    }
  }

  &.active {
    svg {
      transform: rotate(180deg);
    }
  }
`

const Question = styled.h4`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #283445;
  cursor: pointer;
  font-size: 1.15em;
  font-weight: 600;
  padding: 20px 0;
`

const Content = styled.div`
  width: 95%;
  height: ${({ height }) => height}px;
  ${({ height }) => height && css`
    margin-bottom: 20px;
  `}
  overflow: hidden;
  transition: height .35s ease-in-out;
`
