import React from 'react'
import Helmet from 'react-helmet'
import Pricing from '../Pricing'
import PropTypes from 'prop-types'
import Offerings from '../Offerings'
import FAQ from '../FAQ'
import classNames from 'classnames'

class PricingPageTemplate extends React.Component {
  state = {
    currentPlan: {},
  }

  componentDidMount () {
    this.setState({ currentPlan: this.getCurrentPlan() })
  }

  getCurrentPlan = async planNumber => {
    const { edges } = this.props.data

    const selectedPlan = await edges.filter(plan => {
      if (planNumber) {
        return plan.node.frontmatter.plan_month === planNumber
      }
      return plan.node.frontmatter.is_initial_plan
    })

    this.setState({ currentPlan: selectedPlan })
  }

  render () {
    if (this.state.currentPlan[0]) {
      const {
        title,
        meta_title,
        plan_month,
        meta_description,
        pricing,
        months,
        banner3,
        offerings,
        faq,
      } = this.state.currentPlan[0].node.frontmatter

      return (
        <div>
          <Helmet>
            <title>{meta_title}</title>
            <meta name='description' content={meta_description} />
          </Helmet>
          <section className='section section--gradient'>
            <div className='container'>
              <div className='section'>
                <div className='content'>
                  <h2 className='has-text-weight-semibold is-size-3 has-text-centered'>
                    {pricing.heading}
                  </h2>
                  <div className='is-size-5'>
                    <ul className='pricing-description-items'>
                      {pricing.headingitems.map(item => (
                        <li key={item} className='is-size-5'>
                          <img
                            alt='Vantagens da TalkGuest'
                            src='/img/done.png'
                          />
                          {item}
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div>
                    <div className='payment-schedule-buttons buttons has-addons is-centered'>
                      <TabsMenu
                        months={months}
                        planMonth={plan_month}
                        getCurrentPlan={this.getCurrentPlan}
                      />
                    </div>
                    <div
                      className={classNames('discount', {
                        Hidden: pricing.discount == 0,
                      })}
                    >
                      <span className=''>
                        {pricing.discount}% {pricing.discount_label}
                      </span>
                    </div>
                  </div>
                  <Pricing data={pricing.plans} />
                  <div className='CustomPlan'>
                    <Offerings gridItems={offerings.blurbs} />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div className='hp-banner3'>
            <div className='container'>
              <h2>{banner3.heading1}</h2>
              <div className='offer-checklist columns is-multiline'>
                {banner3.checklist && printChecklist(banner3.checklist)}
              </div>

              {(!!banner3.ft_button.url && !!banner3.ft_button.text) && (
                <div className='column trialelement'>
                  <a
                    className='button free-trial-btn2'
                    href={banner3.ft_button.url}
                  >
                    {banner3.ft_button.text}
                  </a>
                </div>
              )}
            </div>
          </div>
          <section className='section'>
            <div className='container'>
              {faq.faq_header && <h3 className='heading'>{faq.faq_header}</h3>}
              {faq.faqs.map((faq, index) => (
                <FAQ key={`faq__${index}`} item={faq} />
              ))}
            </div>
          </section>
        </div>
      )
    }

    return ''
  }
}

function printChecklist (checklistToPrint) {
  checklistToPrint = checklistToPrint || []

  const listItems = checklistToPrint.map(function (item, i) {
    return (
      <div className='column' key={i}>
        <img src='/img/done.png' alt='Vantagens do Channel Manager' />
        {item}
      </div>
    )
  })

  return listItems
}

function TabsMenu ({ months, planMonth, getCurrentPlan }) {
  const { sixmonths_label, oneyear_label, twoyears_label } = months
  const buttons = [sixmonths_label, oneyear_label, twoyears_label]

  function setActive (value) {
    getCurrentPlan(parseInt(value))
  }

  return (
    <div>
      {buttons.map(tab => (
        <Tab
          handleClick={setActive}
          planMonth={planMonth}
          value={tab}
          key={tab}
        />
      ))}
    </div>
  )
}

function Tab (props) {
  const isSelected = props.planMonth === parseInt(props.value) ? 'Selected' : ''

  function handleSelect () {
    props.handleClick(props.value)
  }

  return (
    <button className={`button ${isSelected}`} onClick={handleSelect}>
      <span>{props.value}</span>
    </button>
  )
}

PricingPageTemplate.propTypes = {
  plan_month: PropTypes.number,
  title: PropTypes.string,
  meta_title: PropTypes.string,
  meta_description: PropTypes.string,
  offerings: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
  pricing: PropTypes.shape({
    heading: PropTypes.string,
    headingitems: PropTypes.array,
    description: PropTypes.string,
    plans: PropTypes.array,
    sixmonths_label: PropTypes.string,
    oneyear_label: PropTypes.string,
    twoyears_label: PropTypes.string,
    discount: PropTypes.number,
  }),
}

export default PricingPageTemplate
